body {
    margin: 0;
}

body * {
    font-family: "Exo 2", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media print {
    .no-print, .no-print * {
        display: none !important;
    }
}